import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import backgroundImage from '../../assets/images/lab.jpg'
import IncubationContent from "./incubation-content";

function IncubationAcceleration() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Incubation and Acceleration Lab" bgImage={backgroundImage}/>
            <IncubationContent/>
        </>
    )
}

export default IncubationAcceleration