import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import
{
    projectFacebook,
    projectEmail,
    projectInstagram,
    projectLogo,
    projectPhone,
    projectTwitter,
    projectYoutube,
    projectLinkedIn,
    projectAdmissionSystem
} from '../../resources/constants';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function Main(props)
{
    const navigate = useNavigate();
    const [searchField, setSearchField] = useState("");
    const [Btnshow, setBtnshow] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [Events, setEvent] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [Blog, setblog] = useState(false);
    const [research, setresearch] = useState(false);
    const [innovation, setinnovation] = useState(false);
    const [services, setservices] = useState(false);

    const handleSearchSubmit = (e) =>
    {
        e.preventDefault();
        setBtnshow(false)
        navigate(`/search/${encodeURI(searchField)}`);
    }

    return (
        <>
            <header className="header-area header-three">
                <div className="header-top second-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 d-none d-lg-block ">
                                <div className="header-social">
                                    <span>
                                        Follow us:-
                                        <a href={projectFacebook} title="Facebook" target="_blank">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a href={projectInstagram} title="LinkedIn" target="_blank">
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a href={projectTwitter} title="Twitter" target="_blank">
                                            <i className="fab fa-twitter" />
                                        </a>
                                        <a href={projectYoutube} title="YouTube" target="_blank">
                                            <i className="fab fa-youtube" />
                                        </a>
                                        <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                            <i className="fab fa-linkedin" />
                                        </a>
                                    </span>

                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <i className="icon fal fa-phone" style={{ fontSize: '30px' }} />
                                                </div>
                                                <div className="text">
                                                    <span>Call Now !</span>
                                                    <strong>
                                                        <Link to={projectPhone}>{projectPhone}</Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <i className="icon fal fa-envelope" style={{ fontSize: '30px' }} />
                                                </div>
                                                <div className="text">
                                                    <span>Email Now</span>
                                                    <strong>
                                                        <Link to={projectEmail}> {projectEmail} </Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-sticky" className="menu-area">
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-1 col-lg-1">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={projectLogo} alt="logo" style={{ width: "70px" }} />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="main-menu text-right text-xl-right">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li className="has-sub">
                                                    <Link to="/">About</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/about-us">About Us</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/pc-welcome-message">Pro Chancellor’s Message</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/welcome-message">VC's Message</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/registrar-welcome-message">Registrar’s Message</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about/board-members">Board</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about/management">Management</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about/senate">Our Senate</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/about/gallery">Gallery</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact-us">Contact Us</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Admission</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/course-finder">Course Finder</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/fees">Fees</Link>
                                                        </li>
                                                        <li>
                                                            <a target='_blank' href={projectAdmissionSystem}>Application</a>
                                                        </li>
                                                        <li>
                                                            <Link to="/admission/international-students">International Students</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Academics</Link>
                                                    <ul>
                                                        {
                                                            props.facultyList.length > 0 &&
                                                            props.facultyList.map((r, i) =>
                                                            {
                                                                return (
                                                                    <li key={i}>
                                                                        <Link to={`/faculty/${r.Slug}`}>{r.FacultyName}</Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                        <li>
                                                            <Link to="/our-staff">Staff Directorate</Link>
                                                        </li>

                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Student Life</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/fees/accommodation">Accommodation</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/student-life/life-on-campus">Life on Campus</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/student-life/talk-to-our-students">Talk to Our Students</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/student-life/sport-and-recreation">Sports and Recreation</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Research</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/research/research-area">Research Area</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/research/research-group-and-center">Research Group & Center</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/research/research-culture">Research Culture</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/research/research-seminar">Research Seminar</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="#">Collaboration</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/collaborative-centre-for-ai">Collaborative Centre for AI</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/cwithai">Create with AI Competition</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/partnership">Partnership</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/community-engagement">Community Engagement</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/industry-engagement">Industry Engagement</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/our-staff">Our Staff</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Innovation</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/innovation/maker-space">Maker Space</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/innovation/technology-licensing-office">Technology Licensing Office</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/innovation/incubation-and-acceleration-lab">Incubation & Acceleration Lab</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/innovation/impact-story">Impact Story</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/cwithai">Create with AI Competition</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Services</Link>
                                                    <ul>
                                                        <li>
                                                            <a href="https://staff.cosmopolitan.edu.ng" target='_blank' >Staff Portal</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://student.cosmopolitan.edu.ng" target='_blank' >Student Portal</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://admission.cosmopolitan.edu.ng" target='_blank' >Admission Portal</a>
                                                        </li>
                                                        <li>
                                                            <Link to="/services/library" >Library </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/news">News</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/events">Event</Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`https://jobs.cosmopolitan.edu.ng/`} target='_blank'>Careers</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                                    <div className="login">
                                        <ul>
                                            <li>
                                                <Link to="#" className="menu-tigger" onClick={() => { setBtnshow(true) }}>
                                                    <i className="fal fa-search" onClick={() => { setBtnshow(true) }} />
                                                </Link>
                                            </li>
                                            <li>
                                                <div className="second-header-btn">
                                                    <a href="https://admission.cosmopolitan.edu.ng" target='_blank' className="btn" >apply now</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar">
                                            <a href="#nav" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </a>
                                            {mobile &&
                                                <nav className="mean-nav">
                                                    <ul style={{ display: "block" }}>
                                                        <li className="has-sub">
                                                            <a href="/">About</a>
                                                            {Events &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/about-us" onClick={() => setmobile(!mobile)} >About Us</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/pc-welcome-message">Pro Chancellor’s Message</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/welcome-message">VC's Message</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/registrar-welcome-message">Registrar’s Message</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/about/board-members">Board</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/about/management">Management</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/about/senate">Our Senate</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/about/gallery">Gallery</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/contact-us">Contact Us</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setEvent(!Events) }} href="#" style={{ fontSize: 18 }}>
                                                                {Events ? "-" : "+"}
                                                            </a>
                                                        </li>

                                                        <li className="has-sub">
                                                            <Link to="/">Admission</Link>
                                                            {News &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/course-finder">Course Finder</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/fees">Fees</Link>
                                                                    </li>
                                                                    <li>
                                                                        <a target='_blank' href={projectAdmissionSystem}>Application</a>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/admission/international-students">International Students</Link>
                                                                    </li>
                                                                </ul>
                                                            }

                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setNews(!News) }} href="#" style={{ fontSize: 18 }}>
                                                                {News ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="has-sub">
                                                            <a href="#">Academics</a>
                                                            {Services &&
                                                                <ul style={{ display: "block" }}>
                                                                    {
                                                                        props.facultyList.length > 0 &&
                                                                        props.facultyList.map((r, i) =>
                                                                        {
                                                                            return (
                                                                                <li key={i}>
                                                                                    <Link onClick={() => setmobile(!mobile)} to={`/faculty/${r.Slug}`}>{r.FacultyName}</Link>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }

                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/our-staff">Staff Directorate</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setServices(!Services) }} href="#" style={{ fontSize: 18 }}>
                                                                {Services ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/">Student Life</Link>
                                                            {Blog &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/student-life/accommodation">Accommodation</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/student-life/life-on-campus">Life on Campus</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/student-life/talk-to-our-students">Talk to Our Students</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/student-life/sport-and-recreation">Sports and Recreation</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setblog(!Blog) }} href="#" style={{ fontSize: 18 }}>
                                                                {Blog ? "-" : "+"}
                                                            </a>
                                                        </li>

                                                        <li className="has-sub">
                                                            <Link to="/">Research</Link>
                                                            {research &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/collaborative-centre-for-ai">Centre for AI</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/research/research-area">Research Area</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/research/research-group-and-center">Research Group & Center</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/research/research-culture">Research Culture</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/research/research-seminar">Research Seminar</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setresearch(!research) }} href="#" style={{ fontSize: 18 }}>
                                                                {research ? "-" : "+"}
                                                            </a>
                                                        </li>

                                                        <li className="has-sub">
                                                            <Link to="/">Innovation</Link>
                                                            {innovation &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/innovation/maker-space">Maker Space</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/innovation/technology-licensing-office">Technology Licensing Office</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/innovation/incubation-and-acceleration-lab">Incubation & Acceleration Lab</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/innovation/impact-story">Impact Story</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setinnovation(!innovation) }} href="#" style={{ fontSize: 18 }}>
                                                                {innovation ? "-" : "+"}
                                                            </a>
                                                        </li>

                                                        <li className="has-sub">
                                                            <Link to="/">Services</Link>
                                                            {services &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <a href="https://staff.cosmopolitan.edu.ng" target='_blank' >Staff Portal</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://student.cosmopolitan.edu.ng" target='_blank' >Student Portal</a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://admission.cosmopolitan.edu.ng" target='_blank' >Admission Portal</a>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/services/library" >Library </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/news">News</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} to="/events">Event</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link onClick={() => setmobile(!mobile)} target="_blank" to={`https://jobs.cosmopolitan.edu.ng/`}>Careers</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setservices(!services) }} href="#" style={{ fontSize: 18 }}>
                                                                {services ? "-" : "+"}
                                                            </a>
                                                        </li>

                                                        <li className="mean-last">
                                                            <Link onClick={() => setmobile(!mobile)} to="/contact-us">Contact</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {Btnshow &&
                <>
                    <div className={`offcanvas-menu ${"active"}`}>
                        <span className="menu-close" onClick={() => setBtnshow(false)}>
                            <i className="fas fa-times" onClick={() => setBtnshow(false)} />
                        </span>
                        <form role="search" method="get" id="searchform" className="searchform" onSubmit={handleSearchSubmit} >
                            <input type="text" name="s" id="search" placeholder="Search" value={searchField} onChange={(e) => { setSearchField(e.target.value) }} />
                            <button>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                        <div id="cssmenu3" className="menu-one-page-menu-container">
                            <ul className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/course-finder">Courses</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/fees">Fees </Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/our-staff">Our Staff</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/about/gallery">Gallery</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/news">News</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/events">Events</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="https://jobs.cosmopolitan.edu.ng/" target='_blank'>Careers</Link>
                                </li>

                            </ul>
                        </div>
                        <div id="cssmenu2" className="menu-one-page-menu-container">
                            <ul id="menu-one-page-menu-12" className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#home">
                                        <span>{projectPhone}</span>
                                    </Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#howitwork">
                                        <span>{projectEmail}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`offcanvas-overly ${"active"}`}> </div>
                </>
            }
        </>
    )
}
const mapStateToProps = (state) =>
{
    return {
        facultyList: state.FacultyListDetails
    };
};


export default connect(mapStateToProps, null)(Main);
