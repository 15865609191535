import React from 'react'
import Bredcom from '../Bredcom/bredcom'
import Touch from './Touch'
import Map from './Map'

function ContactUS() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Contact Us"/>
            <Touch/>
            <Map/>
        </>
    )
}

export default ContactUS