import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import SportContent from "./sport-content";
function Sport() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Sport and Recreation"/>
            <SportContent/>
        </>
    )
}

export default Sport