import React from 'react'
import Bredcom from '../Bredcom/bredcom'
import First from "./first";


function InternationalStudent() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="International Student"/>
            <First/>
        </>
    )
}

export default InternationalStudent