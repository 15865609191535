import React  from 'react'
import { Link } from 'react-router-dom';
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
function ResearchCultureContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Research Culture
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we are dedicated to fostering a vibrant research culture that encourages innovation, collaboration, and the pursuit of knowledge. Our commitment to research excellence is reflected in our academic programs, faculty expertise, state-of-the-art facilities, and supportive environment. We strive to create a nurturing ecosystem that enables our researchers, faculty members, and students to thrive and make significant contributions to their respective fields. Here are some key aspects of our research culture:
                                    </p>
                                    <p>
                                        <strong>Collaboration and Interdisciplinary Research: </strong>
                                         We believe in the power of collaboration and interdisciplinary approaches to address complex challenges. We encourage researchers from diverse disciplines to collaborate, share ideas, and work together to create innovative solutions. Our faculty members and students engage in interdisciplinary research projects that bridge traditional boundaries and foster new insights.
                                    </p>
                                    <p>
                                        <strong>State-of-the-Art Facilities and Infrastructure: </strong>
                                        We are committed to providing cutting-edge research facilities and infrastructure to facilitate high-quality research. Our university invests in state-of-the-art laboratories, research centers, libraries, and technology resources to support various research disciplines. We continuously upgrade our facilities to ensure researchers have access to the latest tools and technologies.
                                    </p>
                                    <p>
                                        <strong>Research Ethics and Integrity: </strong>
                                        We uphold the highest standards of research ethics and integrity. Our researchers are committed to conducting research with honesty, transparency, and respect for ethical guidelines. We promote responsible research practices, including the proper handling of data, ethical treatment of research subjects, and adherence to ethical review processes.
                                    </p>
                                    <p>
                                        <strong>Research Dissemination and Knowledge Transfer: </strong>
                                         We recognize the importance of sharing research findings and knowledge with the broader academic community and society. Our researchers actively publish their work in reputable journals, present at conferences, and participate in knowledge exchange activities. We encourage knowledge transfer through collaborations with industry partners, policy-makers, and community organizations to ensure the practical application and societal impact of our research.
                                    </p>

                                    <p>
                                        <strong>Professional Development and Training: </strong>
                                         We prioritize the professional development of our researchers and provide opportunities for continuous learning and skill enhancement. We offer research training programs, workshops, and seminars to equip researchers with the necessary tools, methodologies, and research management skills. We also support researchers in developing their academic and professional networks.
                                    </p>
                                    <p>
                                        <strong>International Collaboration and Partnerships: </strong>
                                        We foster international collaborations and partnerships to broaden our research horizons and facilitate cross-cultural exchange. We actively engage with renowned international institutions, research organizations, and scholars to promote collaboration, joint research projects, and student exchange programs. This global outlook enriches our research culture and expands the scope of our research endeavors
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    Our research culture at Cosmopolitan University encourages curiosity, innovation, and intellectual exploration. We believe that research is a catalyst for positive change and the advancement of society. By nurturing a dynamic research environment, we aim to empower our researchers and students to make significant contributions to their fields of study and address pressing global challenges.
                                </p>
                                <p>
                                    Join us at Cosmopolitan University and be part of a vibrant research community that values excellence, collaboration, and the pursuit of knowledge. Together, let's push the boundaries of research and create a better future.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ResearchCultureContent