import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ImageUrlFormatter, Ordinal, convertTime, formatDateAndTime } from '../../resources/constants'
import { titleCase } from 'title-case'
import * as DOMPurify from 'dompurify'

function HomeNews(props) {
    const [newsList, setEventsList] = useState(props.newsList.length > 0 ? props.newsList : [])
    return (
        <div>
            {
                newsList.length > 0 &&
                <section className="event pt-50 pb-90 p-relative fix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 p-relative">
                                <div
                                    className="section-title center-align mb-50 text-center wow fadeInDown animated"
                                    data-animation="fadeInDown"
                                    data-delay=".4s"
                                >
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> News
                                    </h5>
                                    <h2>News</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                newsList.slice(0, 3).map((x, i) => {
                                    return (
                                        <div key={i} className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >

                                            <div className="event-item mb-30 hover-zoomin">
                                                <div className="thumb">
                                                    <Link to={`/news/${x.Slug}`}>
                                                        <img src={ImageUrlFormatter(x.ImagePath, "news_uploads")} alt="contact-bg-an-01" style={{minHeight:"250px", maxHeight:"250px"}} />
                                                    </Link>
                                                </div>
                                                <div className="event-content">
                                                    <div className="date">
                                                        <strong>
                                                            {new Date(x.InsertedDate).getDate()}
                                                            <sup>{Ordinal(new Date(x.InsertedDate).getDate())}</sup>
                                                        </strong>
                                                        {formatDateAndTime(x.InsertedDate, "month_and_year")}
                                                    </div>
                                                    <h4>
                                                        {
                                                            x.titleCase !== null &&
                                                            <Link to={`/news/${x.Slug}`}>
                                                                {" "}
                                                                {x.Title.length > 50 ? `${titleCase(x.Title.substr(0, 50))}...` : titleCase(x.Title)}
                                                            </Link>
                                                        }
                                                    </h4>
                                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${x.Description.substr(0, 80)}...`) }} />

                                                    {/* <div className="time">
                                                        <strong> <i className="fal fa-user" /> {x.InsertedBy}</strong>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </section>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails
    }
}

export default connect(mapStateToProps, null)(HomeNews)
