import axios from 'axios';
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ImageUrlFormatter, serverLink, shortCode } from '../../resources/constants';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setHRStaffDataDetails, setHRStaffListDetails } from '../../actions/setactiondetails';
import DOMPurify from 'dompurify';

function StaffDetails(props) {
    const dispatch = useDispatch();
    const { slug } = useParams();

    //const navigate = useNavigate();
    const [titles, setTitles] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);

    const [staffRelatedDocuments, setStaffRelatedDocuments] = useState({
        qualifications: [],
        modules: [],
        publications: [],
    });

    let staffRecord = props.hrStaffList.length > 0 ? props.hrStaffList.filter((item) => item.Slug === slug) : [];
    const [staffDetail, setStaffDetail] = useState(
        props.hrStaffList.length > 0 ? props.hrStaffList.filter((item) => item.Slug === slug) : []
    );

    useEffect(() => {
        const slug_record = slug;
        staffRecord.push(
            props.hrStaffList.length > 0 ? props.hrStaffList.filter((item) => item.Slug === slug) : []
        );
        setStaffDetail(props.hrStaffList.length > 0 ? props.hrStaffList.filter((item) => item.Slug === slug) : []);
    }, [slug])

    const getStaffData = async () => {
        await axios.get(`${serverLink}academic/staff/all/`).then(async (response) => {
            const data = response.data;
            const staff_data = data.filter((item) => item.Slug === slug);
            props.setOnHRStaffListDetails(response.data);
            if (data.filter((item) => item.Slug === slug).length < 1) {
                window.location.href = "/"
            } else {
                staffRecord.push(staff_data);
                setStaffDetail(staff_data)
            }
            if (staff_data.length > 0) {
                await axios.get(`${serverLink}academic/staff/${staff_data[0].StaffID}`).then((response) => {
                    setStaffRelatedDocuments(response.data);
                })
                    .catch((error) => {
                        console.log("NETWORK ERROR", error);
                    });
            }

        }).catch((error) => {
            console.log("NETWORK ERROR", error);
        });


    };

    const getData = async () => {
        await axios
            .get(`${serverLink}academic/titles/`)
            .then((response) => {
                setTitles(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/designation/`)
            .then((response) => {
                setDesignation(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academic/departments/`)
            .then((response) => {
                setDepartments(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };
    useEffect(() => {
        getStaffData().then((r) => { });
        getData()
    }, []);



    const updateDownloadCount = async (id) => {
        await axios
            .put(`${serverLink}academic/update/download/count/${id}`)
            .then((response) => { })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const [qoutes, setQuotes] = useState([])


    useEffect(() => {
        axios.get("https://type.fit/api/quotes").then((res) => {
            const item = res.data[Math.floor(Math.random() * res.data.length)];
            setQuotes(item);
        })
    }, [staffDetail]);


    return (
        <>
            <section className="team-area-content">
                {
                    staffDetail.length > 0 &&
                    <div className="container">
                        <div className="lower-content">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <div className="team-img-box">
                                        <Link to="#">
                                            <img src={ImageUrlFormatter(staffDetail[0].Image, "hr/document")} alt="img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="text-column col-lg-7 col-md-12 col-sm-12">
                                    <div className="s-about-content pl-30 wow fadeInRight" data-animation="fadeInRight" data-delay=".2s" >
                                        <h3>
                                            {
                                                titles.length > 0 &&
                                                    titles.filter(x => x.EntryID === staffDetail[0].TitleID).length > 0 ?
                                                    titles.filter(x => x.EntryID === staffDetail[0].TitleID)[0].TitleName : "Mr."
                                            } {" "}
                                            {`${staffDetail[0].Surname} ${staffDetail[0].MiddleName} ${staffDetail[0].FirstName}`}</h3>
                                        <p>
                                            Position : {staffDetail[0].DesignationName}
                                        </p>
                                        <div className="per-info">
                                            <div className="info-text">
                                                <strong>Department</strong>
                                                <p>
                                                    {
                                                        departments.length > 0 &&
                                                        departments.filter(x => x.DepartmentCode === staffDetail[0].DepartmentCode)[0].DepartmentName
                                                    }
                                                </p>
                                                <br />
                                                <p>
                                                    <i className="fal fa-phone" /> {staffDetail[0].PhoneNumber}
                                                </p>
                                                <p>
                                                    <i className="fal fa-envelope" /> {staffDetail[0].EmailAddress}
                                                </p>
                                            </div>
                                            {/* <div className="info-text">
                                                <p>
                                                    <i className="fal fa-phone" /> {staffDetail[0].PhoneNumber}
                                                </p>
                                                <p>
                                                    <i className="fal fa-envelope" /> {staffDetail[0].EmailAddress}
                                                </p>
                                            </div> */}
                                        </div>
                                        {
                                            staffDetail[0].Biography !== "" &&
                                            <>
                                                <h3>About Me</h3>
                                                <div className='text-justify' style={{ textAlign: "justify" }}>
                                                    <p className='text-justify' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staffDetail[0].Biography) }} />
                                                </div>
                                            </>
                                        }

                                        <div className="two-column mt-30">
                                            <div className="row aling-items-center">
                                                <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                                    <div className="footer-social mt-10">
                                                        <a href={staffDetail[0].Facebook} target='_blank'>
                                                            <i className="fab fa-facebook-f" />
                                                        </a>
                                                        <a href={staffDetail[0].Instagram} target='_blank'>
                                                            <i className="fab fa-instagram" />
                                                        </a>
                                                        <a href={staffDetail[0].Twitter} target='_blank'>
                                                            <i className="fab fa-twitter" />
                                                        </a>
                                                        <a href={staffDetail[0].ResearchGate} target='_blank'>
                                                            <i className="fab fa-researchgate" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    {
                                        qoutes.text !== undefined ?
                                            <div className="section-title wow fadeInLeft animated p-3 text-white" style={{ backgroundColor: "#082c3c", borderRadius: "5px" }}
                                                data-animation="fadeInDown animated"
                                                data-delay=".2s"
                                            >
                                                {
                                                    qoutes.text !== undefined &&
                                                    <>
                                                        <h3 style={{ color: "white" }}>Favourite Quote</h3>
                                                        <p className='fw-bold'>{qoutes.text}. -- {qoutes.author}</p></>
                                                }
                                            </div>
                                            :
                                            <div className='align-content-center'>
                                                <i>loading qoute...</i>
                                            </div>
                                    }
                                    <div
                                        className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                                        data-animation="fadeInUp"
                                        data-delay=".4s"
                                    >
                                        <div className="accordion" id="accordionExample">
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h2 className="mb-0">
                                                        <button
                                                            className="faq-btn"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseThree"
                                                        >
                                                            <b>Qualifications</b>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div
                                                    id="collapseThree"
                                                    className="collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="card-body">
                                                        <div className='table-responsive'>
                                                            <table className='table table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <td>Discipline</td>
                                                                        <td>Institution</td>
                                                                        <td>Year Obtained</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        staffRelatedDocuments.qualifications.length > 0 &&
                                                                        staffRelatedDocuments.qualifications.map((x, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{x.Discipline}</td>
                                                                                    <td>{x.InstitutionName}</td>
                                                                                    <td>{x.Year}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h2 className="mb-0">
                                                        <button
                                                            className="faq-btn"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseFour"
                                                        >
                                                            <b>Modules Taken</b>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div
                                                    id="collapseFour"
                                                    className="collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="card-body">
                                                        <div className='table-responsive'>
                                                            <table className='table table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <td>Module</td>
                                                                        <td>Module Code</td>
                                                                        <td>Credit Unit</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        staffRelatedDocuments.modules.length > 0 &&
                                                                        staffRelatedDocuments.modules.map((x, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{x.ModuleDescription}</td>
                                                                                    <td>{x.ModuleCode}</td>
                                                                                    <td>{x.CreditUnit}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h2 className="mb-0">
                                                        <button
                                                            className="faq-btn"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseFive"
                                                        >
                                                            <b>Publications</b>
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div
                                                    id="collapseFive"
                                                    className="collapse"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="card-body">
                                                        <div className='table-responsive'>
                                                            <table className='table table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <td>Title</td>
                                                                        <td>Author(s)</td>
                                                                        <td>Downloads | Views</td>
                                                                        <td>Published Year</td>
                                                                        <td>File</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        staffRelatedDocuments.publications.length > 0 &&
                                                                        staffRelatedDocuments.publications.map((x, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>{x.WorkTitle}</td>
                                                                                    <td>{x.Authors}</td>
                                                                                    <td>{x.DownloadCount} | {x.ViewCount}</td>
                                                                                    <td>{x.PublishedYear}</td>
                                                                                    <td>
                                                                                        <a
                                                                                            style={{
                                                                                                color: "blue",
                                                                                            }}
                                                                                            href={x.UploadFile !== null ?
                                                                                                x.UploadFile.includes("simplefileupload") ? x.UploadFile :
                                                                                                    `${serverLink}public/uploads/${shortCode}/academic/publication/${x.UploadFile
                                                                                                        ? x.UploadFile
                                                                                                        : ""
                                                                                                    }` : ""}
                                                                                            target="_blank"
                                                                                            onClick={() =>
                                                                                                updateDownloadCount(x.EntryID)
                                                                                            }
                                                                                        >
                                                                                            {" "}
                                                                                            Download Paper
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setOnHRStaffListDetails: (p) => {
            dispatch(setHRStaffListDetails(p));
        },
        setOnHRStaffDataDetails: (p) => {
            dispatch(setHRStaffDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails)