import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { ImageUrlFormatter, dynamicDateSort, descDynamicSort, formatDateAndTime, serverLink, projectTwitter, projectFacebook, projectInstagram, projectLinkedIn, projectYoutube } from '../../resources/constants';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import * as DOMPurify from 'dompurify';
import { titleCase } from 'title-case';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fas fa-arrow-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fas fa-arrow-left"></i></button>

    );
}

function Items({ currentItems }) {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
    };
    return (
        <>
            {currentItems &&
                currentItems.map((item, index) => (
                    <div className="bsingle__post mb-50" key={index}>
                        {/* 
                        <Slider className="bsingle__post-thumb blog-active" {...settings}>
                            <div className="slide-post">
                                <img src="assets/img/blog/inner_b3.jpg" alt="" />
                            </div>
                            <div className="slide-post">
                                <img src="assets/img/blog/inner_b2.jpg" alt="" />
                            </div>
                            <div className="slide-post">
                                <img src="assets/img/blog/inner_b1.jpg" alt="" />
                            </div>
                        </Slider> */}
                        <div className="bsingle__post-thumb">
                            <img src={ImageUrlFormatter(item.ImagePath, "news_uploads")} alt="" style={{ height: "400px", width: '100%' }} />
                        </div>
                        <div className="bsingle__content">
                            <div className="meta-info">
                                <ul>
                                    {/* <li>
                                        <i className="fal fa-user" /> {item.InsertedBy}
                                    </li> */}
                                    <li>
                                        <i className="fal fa-calendar-alt" /> {formatDateAndTime(item.InsertedDate, "date")}
                                    </li>
                                </ul>
                            </div>
                            <h2>
                                <Link to={`/news/${item.Slug}`}>
                                    {item.Title.length > 50 ? titleCase(item.Title.toLowerCase().substr(0, 50)) + "..." : titleCase(item.Title.toLowerCase())}
                                </Link>
                            </h2>
                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${item.Description.substr(0, 400)}...`) }} />
                            <div className="blog__btn">
                                <Link to={`/news/${item.Slug}`} className="btn">
                                    Read More <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}


function NewsPage(props) {

    const itemsPerPage = 4
    const [newsList, setNewsList] = useState(props.newsList.length > 0 ? props.newsList : []);
    const getData = async () => {
        await axios.get(`${serverLink}news/all/active`)
            .then((result) => {
                const data = result.data;
                setNewsList(data)
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = newsList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(newsList.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % newsList.length;
        setItemOffset(newOffset);
    };

    const categories = [...new Set(props.newsList.length > 0 ? props.newsList.map((item) => { return item.FacultyCode }) : [])]
    const onSearch = (e) => {
        const value = e.target.value;
        const searchResult = props.newsList.length > 0 ?
            props.newsList.filter(x => x.Title.toLowerCase().includes(value) ||
                x.Description.toLowerCase().includes(value)) : []
        setNewsList(searchResult)
    }

    const onCategoryChange = (value) => {
        const searchResult = props.newsList.length > 0 ?
            props.newsList.filter(x => x.FacultyCode.includes(value)) : []
        setNewsList(value !== 'all' ? searchResult : props.newsList)
    }

    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">

                            <Items currentItems={currentItems} />

                            <div className="pagination-wrap">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={">>"}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="<<"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    pageLinkClassName="page-item"
                                    previousLinkClassName="page-item"
                                    nextLinkClassName="page-item"
                                    activeClassName="page-item active"
                                />
                                {/* <nav>
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a href="#">
                                                <i className="fas fa-angle-double-left" />
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a href="#">1</a>
                                        </li>
                                        <li className="page-item">
                                            <a href="#">2</a>
                                        </li>
                                        <li className="page-item">
                                            <a href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a href="#">...</a>
                                        </li>
                                        <li className="page-item">
                                            <a href="#">10</a>
                                        </li>
                                        <li className="page-item">
                                            <a href="#">
                                                <i className="fas fa-angle-double-right" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="search-3" className="widget widget_search">
                                    <h2 className="widget-title">Search</h2>
                                    <div className="search-form" >
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="search" className="search-field" placeholder="Search …" defaultValue="" name="search" onChange={onSearch} />
                                        </label>
                                        {/* <input type="submit" className="search-submit" defaultValue="Search" /> */}
                                    </div>
                                </section>
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter}>
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube}>
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook}>
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram}>
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn}>
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                {
                                    props.newsList.length > 0 &&
                                    <section id="recent-posts-4" className="widget widget_recent_entries">
                                        <h2 className="widget-title">Recent Posts</h2>
                                        <ul>
                                            {
                                                props.newsList.sort(descDynamicSort("InsertedDate")).slice(0, 4).map((item, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link to={`/news/${item.Slug}`} className='text-body fw-bold' style={{ color: "#a18b5c" }}>
                                                                {item.Title.length > 50 ? titleCase(item.Title.toLowerCase().substr(0, 100)) + "..." :
                                                                    titleCase(item.Title.toLowerCase())}
                                                            </Link><br />
                                                            <span className="post-date">{formatDateAndTime(item.InsertedDate, "date")}</span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </section>
                                }
                                {
                                    categories.length > 0 &&
                                    <section id="tag_cloud-1" className="widget widget_tag_cloud">
                                        <h2 className="widget-title">Tags</h2>
                                        <div className="tagcloud">
                                            {
                                                categories.map((x, i) => {
                                                    const faculty = props.facultyList.length > 0 ?
                                                        props.facultyList.filter(j => j.FacultyCode === x).length > 0 ?
                                                            props.facultyList.filter(j => j.FacultyCode === x)[0].FacultyName : "General"
                                                        : "";
                                                    return (
                                                        <a href="#" key={i} onClick={() => onCategoryChange(x)} className="tag-cloud-link tag-link-28 tag-link-position-1" style={{ fontSize: "8pt" }} aria-label="app (1 item)" >
                                                            {faculty}
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </section>
                                }
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
        facultyList: state.FacultyListDetails
    }
}

export default connect(mapStateToProps, null)(NewsPage);
