import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BredCom from '../Bredcom/bredcom'
import axios from 'axios';
import { ImageUrlFormatter, serverLink } from '../../resources/constants';

function StaffList() {

    const [staffList, setStaffList] = useState([]);
    const [staffList2, setStaffList2] = useState([]);
    const [titles, setTitles] = useState([]);


    const getData = async () => {
        const { data } = await axios.get(`${serverLink}academic/staff/all`);
        setStaffList(data)
        setStaffList2(data);

        await axios
            .get(`${serverLink}academic/titles/`)
            .then((response) => {
                setTitles(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    const onSearch = (e) => {
        const value = e.target.value;
        const filtered = staffList2.length > 0 ?
            staffList2.filter(x => x.Surname.toLowerCase().includes(value.toLowerCase()) ||
                x.MiddleName.toLowerCase().includes(value.toLowerCase()) ||
                x.FirstName.toLowerCase().includes(value.toLowerCase()) ||
                x.DesignationName.toLowerCase().includes(value.toLowerCase()))
            : [];

        setStaffList(filtered);
    }
    return (
        <>
            <BredCom
                title={"Staff"}
                subtitle={"Our Staff"}
            />
            <section className="team-area fix p-relative pt-100 pb-80" style={{ background: "#f7f9ff" }} >
                <div className="container">
                    <div className="row">
                        <section id="search-3" className="widget widget_search">
                            <div className="search-form">
                                <input type="search" className="search-field" placeholder="Search …" onChange={onSearch} name="search" />
                            </div>
                        </section>
                        {
                            staffList.length > 0 &&
                            staffList.map((item, index) => {
                                const staff_name = `${item.Surname} ${item.MiddleName} ${item.FirstName}`;
                                const title = titles.length > 0 &&
                                    titles.filter(x => x.EntryID === item.TitleID).length > 0 ?
                                    titles.filter(x => x.EntryID === item.TitleID)[0].TitleName : "Mr."
                                return (
                                    <div className="col-xl-3 col-md-6" key={index}>
                                        <div className="single-team mb-40">
                                            <div className="team-thumb">
                                                <div className="brd">
                                                    <Link to={`/staff/${item.Slug}`}>
                                                        {" "}
                                                        <img src={ImageUrlFormatter(item.Image, "hr/document")} alt="img" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="team-info">
                                                <h4>
                                                    <Link to={`/staff/${item.Slug}`}>{title}. {staff_name.length > 19 ? `${staff_name.substr(0, 17)}...` : staff_name}</Link>
                                                </h4>
                                                <p>{item.DesignationName}</p>
                                                <div className="team-social">
                                                    <ul>
                                                        <li>
                                                            <a href={item.Facebook} target='_blank'>
                                                                <i className="fab fa-facebook-f" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={item.Instagram} target='_blank'>
                                                                <i className="fab fa-instagram" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={item.Twitter} target='_blank'>
                                                                <i className="fab fa-twitter" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={item.ResearchGate} target='_blank'>
                                                                <i className="fab fa-researchgate" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default StaffList