import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import ResearchSeminarContent from "./research-seminar-content";

function ResearchSeminar() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Research Seminar"/>
            <ResearchSeminarContent/>
        </>
    )
}

export default ResearchSeminar