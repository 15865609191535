import React  from 'react'

function CareerEmploymentContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Career and Employment Support at Cosmopolitan University
                                    </h5>
                                    <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we are committed to empowering our students with the knowledge, skills, and resources they need to succeed in their chosen careers. We understand that education is not just about acquiring knowledge but also about preparing for the professional world. Through our comprehensive career and employment support services, we strive to ensure that our graduates are equipped with the tools and opportunities to launch fulfilling careers. Here's an overview of the career and employment support we offer:
                                    </p>
                                    <p>
                                        <strong>a. Career Guidance and Counseling: </strong>
                                         Our dedicated career advisors provide personalized guidance and counseling to help students explore career options, identify their strengths and interests, and develop a career plan. They offer one-on-one sessions, conduct career assessments, and provide valuable insights into various industries and job markets. Our advisors assist students in making informed career decisions and developing strategies for success.
                                    </p>
                                    <p>
                                        <strong>b. Professional Development Workshops: </strong>
                                         We organize a wide range of professional development workshops to enhance students' employability skills. These workshops cover topics such as resume writing, interview techniques, networking, and job search strategies. We invite industry professionals to share their expertise and provide valuable insights into the expectations and requirements of different industries.
                                    </p>
                                    <p>
                                        <strong>c. Internship and Work Placement Programs: </strong>
                                         We facilitate internships and work placement opportunities with reputable organizations. These programs allow students to gain practical experience, apply their classroom knowledge, and develop essential workplace skills. Through hands-on training, students have the chance to explore their career interests, build industry connections, and enhance their resumes.
                                    </p>
                                    <p>
                                        <strong>d. Job Placement Assistance: </strong>
                                        We have a dedicated job placement office that assists students and alumni in finding employment opportunities. The office maintains a network of industry contacts and collaborates with employers to identify job openings suitable for our graduates. We provide job search resources, interview preparation support, and job application guidance to increase our students' chances of securing meaningful employment.
                                    </p>

                                    <p>
                                        <strong>e. Career Fairs and Networking Events: </strong>
                                         We organize career fairs and networking events where students can connect with potential employers, industry professionals, and alumni. These events provide valuable networking opportunities, allowing students to showcase their skills, learn about job openings, and make valuable connections in their desired fields. We also invite guest speakers from various industries to share insights and offer career advice.
                                    </p>
                                    <p>
                                        <strong>f. Entrepreneurship Support: </strong>
                                         For aspiring entrepreneurs, we provide entrepreneurship support services to help students turn their innovative ideas into successful ventures. We offer workshops, mentorship programs, and access to resources and networks that enable students to develop their business plans, secure funding, and launch their own startups.
                                    </p>
                                    <p>
                                        <strong>g. Career Resource Center: </strong>
                                         Our campus is equipped with a dedicated Career Resource Center, where students can access a wide range of career-related resources. These include job listings, industry reports, career development books, online tools, and professional development materials. The center serves as a hub for career information and self-guided learning, supporting students throughout their career journeys.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    At Cosmopolitan University, we are committed to fostering a strong connection between academia and the professional world. Through our comprehensive career and employment support services, we strive to ensure that our students are well-prepared, confident, and competitive in the job market. We take pride in seeing our graduates thrive in their careers and make a positive impact in their respective fields.
                                </p>
                                <p>
                                    Prepare for a successful future with the career and employment support available at Cosmopolitan University. We are dedicated to helping you achieve your professional goals and embark on a rewarding career journey.
                                </p>
                            </div>
                            <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CareerEmploymentContent