import React from 'react'
import { Link } from 'react-router-dom';
import Img from './mani.jpg'
import Bredcom from "../../Bredcom/bredcom";
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../../resources/constants";

function RegistrarWelcomeMessage() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Pro Chancellor Message" />

            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bsingle__post mb-50">
                                <div className="bsingle__content">
                                    <div className="bsingle__post-thumb mb-20">
                                        <div className="row">
                                            <div className="col-md-10 offset-sm-1">
                                                <img className="img-thumbnail" src={Img} alt="" />
                                                <div className="text-center mt-0">
                                                    <h2 style={{ marginBottom: '-5px' }}>Dr. Mani Ibrahim Ahmad Ph.D. FNIM</h2>
                                                    <small style={{ marginTop: '-50px' }}> Registrar </small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ textAlign: "justify" }}>
                                        <p className="txt-clr" style={{ textAlign: 'justify' }}>
                                            As we welcome you to Cosmopolitan University, we wish to assure you that you indeed made the right decision to enroll as a student of this promising citadel of learning.
                                        </p>
                                        <p>
                                            As our name implies, we are out to promote inclusiveness and diversity by way of attracting both students and staff from all over in order to create a melting pot of ideas that will characterize your stay here. In trying to meet your aspiration, we have created a unique learning environment that is conducive enough to allow you to explore a wide range of possibilities towards achieving your full potentials.
                                        </p>
                                        <p>
                                            We have made the system robust enough with innovative technologies and requisite manpower to support you academically, socially and even spiritually. Our promise of making you ready for the 21st century work environment either as an employer or employee is deeply embedded in what we do and the way we do things.
                                        </p>
                                        <p>
                                            Be rest assured you are starting a journey into the most eventful years of your lives at Cosmopolitan University. All of these will only be possible if you put forward the right attitude towards your studies and stay at Cosmopolitan University. I must add here that while we are going to work extra hard to keep our promise to you, on your part you are expected to live a straight life free of drugs, violence and all forms of malpractices.
                                        </p>
                                        <p>
                                            The system will shield you against all forms of intimidation and harassment. By and large, I wish you all the best of times at Cosmopolitan University.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/about/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default RegistrarWelcomeMessage