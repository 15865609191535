import React from 'react'
import Bredcom from "../../pages/Bredcom/bredcom";
import Main from "./main";

function Fees() {
    return (
        <>
            <Bredcom
                title="Home"
                subtitle="Fees"/>
            <Main/>
        </>
    )
}

export default Fees