import React from 'react'
import image1 from '../../assets/images/COSMOPOLITAN UNIVERSITY 098.jpg'
import { connect } from "react-redux";
import Frequently from "./frequently";


function University(props) {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={image1} alt="img" />
                                {/* <div className="about-text second-about three-about">
                                    <span>
                                        5 <sub>+</sub>
                                    </span>
                                    <p>Years of Experience</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> About Our University
                                    </h5>
                                    <h2>A Few Words About the University</h2>
                                </div>
                                {/*<div*/}
                                {/*    dangerouslySetInnerHTML={{ __html: props.homeData.AboutContent }} style={{textAlign: "justify"}}*/}
                                {/*/>*/}
                                <p className="text-dark-emphasis" style={{ textAlign: "justify", color: "black" }}>
                                    Welcome to Cosmopolitan University! Cosmopolitan University is a pioneering institution approved by the Federal Executive Council on May 16th, 2023. Our university is dedicated to providing students with a student-centred learning experience that is both innovative and of international standard. With a focus on research, education, and active engagement with industry and society, we aim to expand knowledge and prepare graduates to make a positive impact in their workplaces, environment, and communities
                                </p>
                                <p style={{ textAlign: "justify", color: "black" }}>
                                    At Cosmopolitan University, we embrace technology-driven learning, recognising the transformative power it holds in today's world. Our commitment to research-driven innovation keeps us updated with cutting-edge discoveries and development. While we embrace the possibilities offered by artificial intelligence, we will endeavour to put appropriate regulations in place to ensure ethical and responsible use. One of the distinguishing features of Cosmopolitan University is our international faculty, comprised of experienced educators from around the world. This diverse and accomplished team bring a wealth of knowledge and global perspectives to our classrooms, enriching the learning experience for our students.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Frequently />
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};
export default connect(mapStateToProps, null)(University);

