import React, {useState} from 'react'
import backgroundImage from '../../assets/images/tech.jpg'
import {Link} from "react-router-dom";

function TechnologyLicensingOfficeContent() {
    const [video, setVideo] = useState();

    return (
        <>
            <section className="event event03 pt-50 pb-120 p-relative fix text-start">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2> <i className="fal fa-temperature-high" /> Welcome to the Technology Licensing Office at Cosmopolitan University!</h2>
                            <p>The Technology Licensing Office (TLO) is dedicated to facilitating the transfer of university technologies to the marketplace. We serve as a bridge between the innovative research conducted at Cosmopolitan University and the commercial sector, fostering collaboration and driving economic impact. Our mission is to support the successful commercialization of intellectual property developed by our faculty, researchers, and students.</p>
                        </div>
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: `url(${backgroundImage})`}} >
                                <div className="s-video-content text-center">
                                    {/* <h6>
                                        <Link to="#" className="popup-video mb-50" onClick={() => setVideo(true)}>
                                            <img src="/assets/img/bg/play-button.png" alt="circle_right" />
                                        </Link>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                <h4>Here's what you can expect from our Technology Licensing Office: </h4>
                                <div className="accordion" id="accordionCU">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" >
                                                    Technology Transfer
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We work closely with researchers to identify, protect, and commercialize their inventions and discoveries. Our team assists in evaluating the commercial potential of technologies, filing patent applications, and securing intellectual property rights. We aim to maximize the societal impact of our research by transforming it into products, services, and technologies that benefit industries and society as a whole.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                                    Industry Collaboration
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We actively seek partnerships and collaborations with industry stakeholders, including companies, entrepreneurs, and investors. By connecting our innovative technologies with industry needs, we facilitate the development of mutually beneficial relationships. Through licensing agreements, research collaborations, and sponsored projects, we promote the transfer of knowledge and the advancement of cutting-edge research.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
                                                    Licensing and Commercialization
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                The TLO manages the licensing process, enabling interested parties to access and utilize our intellectual property for commercial purposes. We negotiate licensing agreements that protect the rights of our inventors while providing opportunities for businesses to develop and market new products and services based on our technologies. Our goal is to facilitate the successful transition of university inventions from the lab to the market.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" >
                                                    Start-up Support
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We actively support the creation and growth of start-up companies based on university technologies. Our team provides guidance and resources to entrepreneurs, helping them navigate the process of launching a new venture. We offer assistance in business development, access to funding opportunities, and mentorship to foster the growth of innovative start-ups.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" >
                                                    Intellectual Property Education
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse show" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                 We promote awareness and understanding of intellectual property (IP) rights and the commercialization process. Through workshops, seminars, and training sessions, we educate our researchers, students, and faculty about the importance of protecting their intellectual assets and the potential for commercializing their innovations. By fostering an IP-savvy culture, we encourage innovation and entrepreneurship within the university community.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 text-center">
                            <p>The Technology Licensing Office at Cosmopolitan University serves as a catalyst for innovation, entrepreneurship, and economic growth. We are committed to unlocking the full potential of our research and creating meaningful partnerships with industry. Together, we can translate groundbreaking discoveries into real-world solutions and drive positive change. If you are interested in collaborating, licensing, or exploring our portfolio of technologies, we invite you to connect with our Technology Licensing Office team. Let's transform ideas into impactful realities! .</p>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style={{overflow: "hidden"}}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)} >×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/ZSiXZxVpVhs?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default TechnologyLicensingOfficeContent