import React, { useEffect, useState } from 'react'
import Bredcom from "../../../component/Bredcom/Main";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { currencyConverter, currencyConverterDollar, projectAdmissionSystem, serverLink, shortCode } from "../../../resources/constants";
import courseImg1 from '../../../assets/images/course.png';
import { setCourseDataDetails, setCourseListDetails } from "../../../actions/setactiondetails";
import * as DOMPurify from 'dompurify';
import "./style.css";
import SpinnerLoader from "../../../component/common/spinner-loader";
function CourseDetails(props) {
    const location = useLocation();
    const { slug } = useParams();

    if (slug === "") {
        window.location.href = "/admission/course-finder";
    }
    const [tuitionDetails, setTuitionDetails] = useState([])
    const [loading, setLoading] = useState(true);
    let courseList = props.courseList.filter((item) => item.Slug === slug);
    const [courseDetail, setCourseDetail] = useState(
        courseList.length > 0
            ? props.courseDetails.length > 0 ?
                props.courseDetails.filter(
                    (item) => item.CourseCode === courseList[0].CourseCode
                )
                : [] : []
    );



    useEffect(() => {
        const slug_record =
            location.pathname.split("/")[location.pathname.split("/").length - 1];
        courseList.push(
            props.courseList.filter((item) => item.Slug === slug_record)
        );
        setCourseDetail(
            props.courseDetails.length > 0 ?
                props.courseDetails.filter(
                    (item) => item.CourseCode === courseList[0].CourseCode
                ) : []
        );
        axios
            .get(`${serverLink}academics/tuition/by/Level/${courseList[0].CourseCode}`)
            .then((response) => {
                setTuitionDetails(response.data)
                if (courseDetail.length === 0) {
                    window.location.href = "/admission/course-finder";
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }, [location.pathname]);

    const getCourseData = async () => {
        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((response) => {
                const data = response.data;
                const course_data = data.filter((item) => item.Slug === slug);
                props.setOnCourseListDetail(response.data);
                if (data.filter((item) => item.Slug === slug).length < 1) {
                    window.location.href = "/admission/course-finder";
                } else {
                    courseList.push(course_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/course/details`)
            .then((response) => {
                props.setOnCourseData(response.data);
                setCourseDetail(
                    response.data.filter(
                        (item) => item.CourseCode === courseList[0].CourseCode
                    )
                );
                if (courseDetail.length === 0) {
                    window.location.href = "/admission/course-finder";
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

    };

    useEffect(() => {
        getCourseData();
    }, [courseDetail.length === 0]);

    return (courseDetail.length < 1 ? <SpinnerLoader /> :
        <>
            <Bredcom title="Home" subtitle={courseList[0].CourseName} />
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h3>{courseList[0].CourseName}</h3>
                                <div className="upper-box p-0 m-0">
                                    <div className="single-item-carousel owl-carousel owl-theme p-0 m-0">
                                        <figure className="p-0 m-0">
                                            <img
                                                src={courseDetail.length > 0 ? courseDetail[0].ImagePath !== null ? courseDetail[0].ImagePath.includes("simplefileupload") ? courseDetail[0].ImagePath : `${serverLink}public/uploads/${shortCode}/course_uploads/${courseDetail[0].ImagePath}` : courseImg1 : courseImg1}
                                                alt={""}
                                                style={{ width: '100%', padding: '0px' }}
                                            />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column pt-0 mt-0">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                            <li className="nav-item  col-md-4" role="presentation">
                                                <button className="nav-link bgr active w-100 text-white" id="pills-home-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-home"
                                                    type="button" role="tab" aria-controls="pills-home"
                                                    aria-selected="true">Overview
                                                </button>
                                            </li>
                                            <li className="nav-item col-md-4" role="presentation">
                                                <button className="nav-link bgr w-100 text-white" id="pills-profile-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                    type="button" role="tab" aria-controls="pills-profile"
                                                    aria-selected="false">Curriculum
                                                </button>
                                            </li>
                                            <li className="nav-item col-md-4" role="presentation">
                                                <button className="nav-link bgr w-100 text-white" id="pills-contact-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-contact"
                                                    type="button" role="tab" aria-controls="pills-contact"
                                                    aria-selected="false">Staff
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <div
                                                style={{ textAlign: "justify" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(courseDetail[0].Description),
                                                }}
                                            />
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <h3 className="instructor-title mt-2 ">Curriculum</h3>
                                            <div className="alert alert-info mb-3 mt-2 text-center">Coming
                                                Soon
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                            aria-labelledby="pills-contact-tab">
                                            <h3 className="instructor-title mt-3 ">Instructors</h3>
                                            <div className="alert alert-info mb-3 mt-2 text-center">Coming
                                                Soon
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 p-0 m-0">
                                <aside className="sidebar-widget info-column" style={{ width: '100%' }}>
                                    <div className="inner-column3" style={{ width: '100%' }}>
                                        <h3>Course Features</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-clock" />{" "}
                                                <strong>Duration: </strong> <span> {courseList[0].Duration} Years</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-graduation-cap" />{" "}
                                                <strong>Degree In View: </strong> <span> {courseList[0].DegreeInView}</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <a href={projectAdmissionSystem} target={"_blank"} className="btn ss-btn smoth-scroll">
                                                        Apply Now <i className="fal fa-long-arrow-right" />
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                        <section id="categories-1" className="widget widget_categories p-3 mt-3" style={{ width: '100%' }}>
                                            <h3 className="widget-title ">Tuition (Per Semester)</h3>
                                            <ul>
                                                <table className="table table-responsive-md table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>Level</strong></td>
                                                            <td><strong>Local</strong></td>
                                                            <td><strong>Intl.</strong></td>
                                                        </tr>
                                                        {tuitionDetails.filter(
                                                            (item) => item.CourseCode === courseList[0].CourseCode
                                                        ).length > 0 &&
                                                            tuitionDetails
                                                                .filter(
                                                                    (item) => item.CourseCode === courseList[0].CourseCode
                                                                )
                                                                .map((tuition, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td> {tuition.Level}</td>
                                                                            <td> {currencyConverter(tuition.TuitionAmount)}</td>
                                                                            <td> {currencyConverterDollar(tuition.TuitionAmountIntl)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                    </tbody>
                                                </table>

                                            </ul>

                                            <h3 className="widget-title">Discounts</h3>

                                            <ul>
                                                <table className="table table-responsive-md table-striped">
                                                    <tr>
                                                        <td style={{textAlign:"start"}}><strong>Pioneer students</strong></td>
                                                        <td>10%</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{textAlign:"start"}}><strong>Straight A's or 300+ in JAMB</strong></td>
                                                        <td>10%</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{textAlign:"start"}}><strong>Siblings</strong></td>
                                                        <td>10%</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{textAlign:"start"}}><strong>GPA of 3.6/4 or 4.5/5 in their first semester</strong></td>
                                                        <td>10%</td>
                                                    </tr>
                                                </table>
                                            </ul>

                                            <h3 className="widget-title mt-4">Related Courses</h3>
                                            <ul>
                                                {props.courseList.filter(
                                                    (item) => item.FacultyCode === courseList[0].FacultyCode
                                                ).length > 0 &&
                                                    props.courseList
                                                        .filter(
                                                            (item) => item.FacultyCode === courseList[0].FacultyCode
                                                        )
                                                        .map((course, index) => {
                                                            if (course.CourseCode !== courseList[0].CourseCode) {
                                                                return (
                                                                    <>
                                                                        <li className="cat-item cat-item-16"
                                                                            key={index}
                                                                        >
                                                                            <Link to={`/course/${course.Slug}`}>
                                                                                {course.CourseName}
                                                                            </Link>
                                                                        </li>
                                                                        <hr style={{ border: '1px solid #cccccc', margin: '4px' }} />
                                                                    </>
                                                                );
                                                            }
                                                        })}
                                            </ul>
                                        </section>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
        courseDetails: state.CourseDataDetails,
        departmentList: state.DepartmentListDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetail: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseData: (p) => {
            dispatch(setCourseDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
