import React  from 'react'
import { Link } from 'react-router-dom';
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
function ResearchSeminarContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Research Seminar
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        The Research Seminar at Cosmopolitan University serves as a platform for intellectual exchange, collaboration, and the dissemination of cutting-edge research. Our seminars provide a forum for researchers, faculty members, students, and guest speakers to share their research findings, discuss emerging trends, and engage in interdisciplinary conversations. These seminars contribute to the vibrant research culture within our university and foster a spirit of intellectual curiosity. Here's an overview of our Research Seminar program:
                                    </p>
                                    <p>
                                        <strong>Purpose: </strong>
                                         The primary purpose of our Research Seminar series is to promote scholarly dialogue and facilitate the exchange of ideas among researchers, faculty members, and students. The seminars cover a wide range of research topics across various disciplines, reflecting the diversity of our academic programs.
                                    </p>
                                    <p>
                                        <strong>Scope: </strong>
                                         Our Research Seminar series encompasses both faculty-led presentations and invited talks by renowned scholars and experts from academia, industry, and other research institutions. The seminars cover fundamental and applied research, theoretical frameworks, methodologies, empirical studies, and the latest advancements in various fields.
                                    </p>
                                    <p>
                                        <strong>Topics: </strong>
                                        The seminar topics are wide-ranging and cover disciplines offered at Cosmopolitan University. These may include, but are not limited to, management and social sciences, medical sciences, science and engineering, technology, humanities, and arts. The seminars address contemporary issues, emerging research areas, and interdisciplinary themes to foster cross-pollination of ideas.
                                    </p>
                                    <p>
                                        <strong>Format: </strong>
                                        The Research Seminar series typically consists of scheduled presentations followed by interactive discussions and Q&A sessions. Presenters have the opportunity to showcase their research projects, share their findings, methodologies, and insights with a diverse audience. The seminars encourage active participation, collaboration, and constructive feedback to promote academic growth.
                                    </p>

                                    <p>
                                        <strong>Guest Speakers: </strong>
                                         We invite esteemed guest speakers, scholars, and experts to deliver keynote lectures and presentations as part of our Research Seminar series. These speakers bring valuable perspectives, expertise, and experience from their respective fields, enhancing the intellectual discourse and broadening our research horizons.
                                    </p>
                                    <p>
                                        <strong>Participation: </strong>
                                        The Research Seminar series is open to all members of the university community, including faculty members, researchers, students, and staff. We encourage active participation and engagement from attendees, fostering a supportive environment for intellectual exchange, networking, and interdisciplinary collaboration.
                                    </p>
                                    <p>
                                        <strong>Schedule and Announcements: </strong>
                                        The Research Seminar series follows a regular schedule, with seminars held at designated intervals throughout the academic year. Announcements regarding upcoming seminars, topics, and guest speakers are disseminated through university channels, including the website, newsletters, email notifications, and notice boards.
                                    </p>
                                    <p>
                                        <strong>Research Collaboration Opportunities: </strong>
                                        The Research Seminar series also serves as a platform for fostering research collaborations among attendees. Researchers and faculty members can identify shared research interests, potential collaborations, and opportunities for joint projects, funding applications, and publications.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    Our Research Seminar series is an integral part of our commitment to fostering a vibrant research culture at Cosmopolitan University. Through these seminars, we aim to promote research excellence, facilitate interdisciplinary dialogue, and create a supportive environment for the dissemination and advancement of knowledge.
                                </p>
                                <p>
                                    Join us at Cosmopolitan University and be part of our Research Seminar series, where intellectual curiosity is nurtured, ideas are shared, and research collaborations thrive. Together, let's explore new frontiers of knowledge and contribute to the advancement of our respective fields.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ResearchSeminarContent