import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import ResearchCultureContent from "./research-culture-content";

function ResearchCulture() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Research Culture"/>
            <ResearchCultureContent/>
        </>
    )
}

export default ResearchCulture