import React, { useEffect, useState } from 'react'
import BredCom from '../Bredcom/bredcom'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ImageUrlFormatter, Ordinal, convertTime, formatDateAndTime, serverLink } from '../../resources/constants';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { titleCase } from 'title-case';
import * as DOMPurify from 'dompurify';


function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
                currentItems.map((item, index) => (
                    <div key={index} className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="event-item mb-30 hover-zoomin">
                            <div className="thumb">
                                <Link to={`/event/${item.Slug}`}>
                                    <img src={ImageUrlFormatter(item.ImagePath, "event_uploads")} alt="contact-bg-an-01" />
                                </Link>
                            </div>
                            <div className="event-content">
                                <div className="date">
                                    <strong>
                                        {new Date(item.EventDate).getDate()}<sup>{Ordinal(new Date(item.EventDate).getDate())}</sup>
                                    </strong>
                                    {formatDateAndTime(item.EventDate, "month_and_year")}
                                </div>
                                <h4>
                                    <Link to={`/event/${item.Slug}`}>
                                        {" "}
                                        {item.Title.length > 50 ? titleCase(item.Title.toLowerCase().substr(0, 50)) + "..." : titleCase(item.Title.toLowerCase())}
                                    </Link>
                                </h4>
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.Description.substr(0, 100)) }} />
                                <div className="time">
                                    <i className='fa fa-clock' />{convertTime(item.StartTime)} - {convertTime(item.EndTime)}<br />
                                    <i className="fa fa-location" />{" "}
                                    <strong>{item.Location}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}


function Events(props) {

    const itemsPerPage = 12
    const [eventsList, setEventsList] = useState(props.eventsList.length > 0 ? props.eventsList : []);
    const getData = async () => {
        await axios.get(`${serverLink}event/all/active`)
            .then((result) => {
                const data = result.data;
                setEventsList(data)
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = eventsList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(eventsList.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % eventsList.length;
        setItemOffset(newOffset);
    };


    return (
        <div>
            <BredCom title="Events"
                subtitle="Our Events" />
            <section className="shop-area pt-120 pb-120 p-relative " data-animation="fadeInUp animated" data-delay=".2s" >
                <div className="container">
                    <div className="row">
                        <Items currentItems={currentItems} />

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="pagination-wrap mt-20 text-center">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel={">>"}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="<<"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination"
                                    pageLinkClassName="page-item"
                                    previousLinkClassName="page-item"
                                    nextLinkClassName="page-item"
                                    activeClassName="page-item active"
                                />
                            </div>
                        </div>
                        <div />
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        eventsList: state.EventDetails
    }
}

export default connect(mapStateToProps, null)(Events);
