import React from 'react'
import {Link} from "react-router-dom";
import Accommodation from '../../assets/images/COSMOPOLITAN UNIVERSITY 071.jpg'
import Feeding from '../../assets/images/COSMOPOLITAN UNIVERSITY 075.jpg'
import Fees from '../../assets/images/fees.jpg'

function Main() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head text-center">
                                    <h3>Tuition Fee</h3>
                                    {/*<div className="price-count">*/}
                                    {/*    <p>Find out everything you need to know to plan your student finances</p>*/}
                                    {/*</div>*/}
                                    <hr />
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <img src={Fees} alt="Image" className="img-fluid" style={{minHeight: '250px', maxHeight: '250px'}} />

                                </div>
                                <div className="pricing-btn">
                                    <Link to="/fees/tuition" className="btn ss-btn">
                                        Tuition Fee<i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 active mb-60">
                                <div className="pricing-head text-center">
                                    <h3>Accommodation</h3>
                                    <hr />
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <img src={Accommodation} alt="Image" className="img-fluid" style={{minHeight: '250px', maxHeight: '250px'}} />
                                </div>
                                <div className="pricing-btn">
                                    <Link to="/fees/accommodation" className="btn ss-btn">
                                        Accommodation <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="pricing-box pricing-box2 mb-60">
                                <div className="pricing-head text-center">
                                    <h3>Feeding</h3>
                                    <hr />
                                </div>
                                <div className="pricing-body mt-20 mb-30 text-left">
                                    <img src={Feeding} alt="Image" className="img-fluid" style={{minHeight: '250px', maxHeight: '250px'}}/>
                                </div>
                                <div className="pricing-btn">
                                    <Link to="/fees/feeding" className="btn ss-btn">
                                        Feeding <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main