import React  from 'react'
import { Link } from 'react-router-dom';
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
function ResearchContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Research Areas
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        At Cosmopolitan University, we foster a culture of innovation, research, and discovery. Our faculty members and students actively engage in cutting-edge research across a range of disciplines, addressing societal challenges, advancing knowledge, and making a positive impact on industries and communities. Our research areas align with the academic programs we offer, creating opportunities for students to collaborate with faculty members and contribute to the frontiers of knowledge. Here are some of the key research areas within our programs:
                                    </p>
                                    <p>
                                        <h3>Faculty of Management and Social Sciences: </h3>
                                        Accounting and Financial Management: Research areas include financial reporting and analysis, corporate governance, risk management, auditing practices, and sustainable finance.
                                    </p>
                                    <p>
                                        <strong>Business Administration: </strong>
                                         Research focuses on areas such as strategic management, organizational behavior, entrepreneurship, marketing, and innovation management.
                                    </p>
                                    <p>
                                        <strong>Entrepreneurship: </strong>
                                         Research explores entrepreneurial ecosystems, new venture creation, social entrepreneurship, innovation and technology commercialization, and entrepreneurial finance.
                                    </p>
                                    <p>
                                        <strong>Human Resource Management: </strong>
                                         Research areas encompass talent management, organizational development, employee well-being, diversity and inclusion, and human resource analytics.
                                    </p>
                                    <p>
                                        <strong>Development Economics: </strong>
                                        Research examines economic growth and development, poverty alleviation, income inequality, trade and globalization, and sustainable development.
                                    </p>
                                    <p>
                                        <strong>Mass Communication: </strong>
                                        Research focuses on media studies, journalism, digital media, public relations, advertising, and media effects.
                                    </p>
                                    <p>
                                        <strong>ReInternational Relations: </strong>
                                        Research areas encompass global governance, international security, diplomatic studies, regional integration, foreign policy analysis, and conflict resolution.
                                    </p>
                                    <p>
                                        <h3>Faculty of Medical Sciences: </h3>
                                        Research areas encompass global governance, international security, diplomatic studies, regional integration, foreign policy analysis, and conflict resolution.
                                    </p>
                                    <p>
                                        <strong>Medical Laboratory Science: </strong>
                                         Research encompasses medical diagnostics, laboratory techniques and methodologies, biomedical research, clinical laboratory management, and molecular diagnostics.
                                    </p>
                                    <p>
                                        <strong>Public Health: </strong>
                                        Research focuses on epidemiology, health promotion, disease prevention, health policy and management, global health, environmental health, and public health interventions.
                                    </p>
                                    <p>
                                        <h3>Faculty of Science and Engineering: </h3>
                                        Computer Science: Research areas include artificial intelligence, machine learning, data science, computer vision, natural language processing, cyber security, and software engineering.
                                    </p>
                                    <p>
                                        <strong>Cyber security: </strong>
                                        Research focuses on network security, cryptography, threat intelligence, secure software development, digital forensics, and privacy-enhancing technologies.
                                    </p>
                                    <p>
                                        <strong>Information Systems: </strong>
                                        Research areas encompass enterprise systems, data analytics, business intelligence, information security, IT governance, and digital transformation.
                                    </p>
                                    <p>
                                        <strong>Microbiology: </strong>
                                        Research encompasses microbial genetics, medical microbiology, environmental microbiology, industrial microbiology, microbial ecology, and antimicrobial resistance.
                                    </p>
                                    <p>
                                        <strong>Software Engineering: </strong>
                                         Research focuses on software development methodologies, software testing and quality assurance, software project management, software maintenance, and software engineering education.
                                    </p>
                                    <p>
                                        <strong>Computer Engineering: </strong>
                                        Research areas cover computer architecture, embedded systems, hardware design, digital systems, computer networks, and high-performance computing.
                                    </p>
                                    <p>
                                        <strong>Electrical & Electronics Engineering: </strong>
                                        Research focuses on power systems, renewable energy, control systems, telecommunications, electronics design, signal processing, and robotics.
                                    </p>
                                    <p>
                                        <strong>Telecommunications: </strong>
                                        Telecommunications Engineering: Research areas encompass wireless communication systems, network protocols, mobile computing, satellite communication, optical communication, and Internet of Things (IoT) technologies.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    These research areas represent the ongoing academic endeavors and expertise within our university. At Cosmopolitan University, we encourage students to actively engage in research activities and collaborate with faculty members to explore new frontiers in their respective fields. Through research, we strive to generate knowledge, drive innovation, and contribute to the advancement of society and industries.
                                </p>
                                <p>
                                    Join us at Cosmopolitan University and be part of our vibrant research community, where curiosity meets discovery and ideas shape the future.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories p-3  mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ResearchContent