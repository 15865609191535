import React from 'react'
import { Link } from 'react-router-dom'
import { projectAdmissionSystem } from '../../resources/constants'

export default function HomeAdmission() {
    return (
        <div>
            <section className="about-area about-p pt-0 pb-120 p-relative fix" style={{ backgroundImage: "url(assets/img/bg/admission_bg.png)", backgroundRepeat: "no-repeat", backgroundPosition: "top" }} >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src={require("../../assets/images/COSMOPOLITAN UNIVERSITY 099.jpg")} alt="img"
                                    style={{ borderRadius: "10px" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h2>Admission</h2>
                                </div>
                                <p className="">
                                    Ready to embark on an enriching educational experience? Take the first step towards your future by applying to Cosmopolitan University. Our vibrant academic community offers a wide range of programs, dedicated faculty, and state-of-the-art facilities.
                                </p>
                                <p className="">
                                    Whether you're a high school graduate, transfer student, or international applicant, our streamlined application process makes it easy to apply.
                                    <br />
                                    Join a diverse and inclusive campus, unlock your potential, and seize the opportunities that await.
                                </p>
                                <div className="slider-btn mt-20">
                                    <a href={projectAdmissionSystem} target='_blank' className="btn ss-btn smoth-scroll">
                                        Apply <i className="fal fa-long-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
