import React from 'react'

function Frequently() {
    return (
        <>
            <div className="col-lg-12" style={{ color: "black" }}>
                <div
                    className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                >
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                    >
                                        Our Mission
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse show"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    To expand knowledge through research, integrated with education and active engagement with industry and society.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                    >
                                        Our Vision
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseOne"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    To be a student-centred university offering applied and innovative experimental programs to prepare graduates to make a positive impact in their respective workplaces, environment, and communities.
                                </div>
                            </div>
                        </div>
                        <h3>Our Promise</h3>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                    >
                                        Technology-driven learning
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-bod p-5" style={{ textAlign: "justify" }}>
                                    At Cosmopolitan University, we deploy emerging technology to support service delivery, recognising the transformative power it holds in today's world. We will support students and staff with access to critical emerging technologies to support teaching and learning, assessment and evaluation, research, collaboration and engagement. We will also use technologies to drive value for money in all our investments and programs and ensure efficiency and efficacy of service delivery. <br />
                                    Our learning management systems integrated with virtual and augmented reality, gamification, big data, learning analytics and problem-solving techniques will support us to:
                                    <ol>
                                        <li>●	Provide personalised learning experiences to our students by understanding their specific needs and provide them with intervention and feedback to make their learning fun, interactive and engaging; at the same time, give them an experiential learning hub to identify their potential at an early stage and profile them to take leadership roles in their post-graduate journey.</li>
                                        <li>●	Make our students learning more engaging by using games, simulations, modelling, visualisation and other interactive activities, to stay motivated and interested in learning.</li>
                                        <li>●	Provide our students with comprehensive and improved evaluation and assessment feedback and match their aspirations with our pedagogy and support systems</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" id="headingSix">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseSix"
                                    >
                                        Research-driven innovation
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseSix"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    Our research is based on the principles of rigour, relevance, impact, and diversity. To this end we encourage impactful curiosity-led research, adopting disciplinary excellence, and supporting interdisciplinary collaboration internally, nationally, and internationally. We have solid research training with strong ethical and social values, encouraging continual development as active researchers from undergraduates through to professors. Our research aims to address some of the most significant and important challenges facing society in Nigeria, Africa and globally. Consequently, our research will be relevant and impactful, and will directly inform our high-quality courses ensuring they are continually current and relevant.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingSeven">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseSeven"
                                    >
                                        Embracing Artificial Intelligence (AI) with appropriate regulations
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseSeven"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    At Cosmopolitan University, we will sign and abide with “Call for an AI Ethics”. The Call for AI Ethics will support us in implementing AI solutions to accelerate our effort as well as approach future AI innovation and promote a sense of responsibility. We will support creating a future in which digital innovation and technological progress serve human genius and creativity and not their gradual replacement.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingEight">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseEight"
                                    >
                                        International Faculty teaching from all over the world
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseEight"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    Our teaching aims to provide high-quality courses providing robust learning experiences preparing students for their careers. We draw upon an international faculty and resources bringing global best practices and insights. Our courses use the best of learning materials from around the world ensuring exacting standards making our graduates very marketable in their professional careers and thought leaders of the future
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingNine">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseNine"
                                    >
                                        Prudent financial management
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseNine"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    We adopt strong governance processes to ensure our governance supports the scale and complexity of our dynamic and growing organisation.  We achieve financial sustainability by ensuring sufficient funds to continue to invest prudently and confidently to meet our aspirations and maintain and enhance our physical estate, facilities and digital infrastructure.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTen">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTen"
                                    >
                                        Reward system for staff that will measure and match performance with output
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTen"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    At Cosmopolitan University, our students deserve the best possible outcomes from the systems and processes we implement for their education. <br />
                                    Our staff are an integral part of our success factor in this regard. To ensure optimal performance by our staff, we will keep our staff happy and loyal by implementing a reward system that rewards outputs and outcomes.
                                    <br />To ensure an objective assessment, we will continually measure the risk factors of each role to ensure that we put in place an enabling environment (culture, equipment, materials, training among others) for our staff to give us their best.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingEleven">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseEleven"
                                    >
                                        Unparalleled welfare and learning experience for students
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseEleven"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    We appreciate that university may be the first time many students are away from home. We will do our utmost to make our university home from home for our students. Through our unique pastoral and mentorship system, every student will have support from the day they enroll to the day they graduate. <br />
                                    We will ensure students stay active and focused through sports, games and social activities that add value. Our innovative Maker Space will allow students to acquire life-long skills as a hobby or to prepare them for work as an employee or an employer. <br />
                                    We recognise that the jobs the students of today may do in the future may yet to be invented. This is why we are determined to provide our students with flexible technological and information systems skills that will allow them to adapt to the uncertainties the future may bring.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                    >
                                        Robust Academic Governance
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    <p>
                                        To achieve our aims for high academic standards and rigorous impactful research we also need to ensure we have strong governance processes covering collecting and maintain appropriate records, documenting how we spend our funds, how we are working towards being open and inclusive and how we are achieving prudent financial and operational sustainability. <br />
                                        Our corporate governance processes, standards and responsibilities play a significant role in shaping our work activity. Moreover, our teaching and assessment process is such that no student will gain advantage on the basis of gender, social status or any other parameter.

                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header" id="headingFour">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                    >
                                        Equality of opportunity for all stakeholders
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseFour"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    Cosmopolitan University is an equal-opportunity institution. Our policies and procedures will be subjected to an Equality Impact Assessment to ensure no direct or indirect unlawful discrimination of any student, staff or stakeholder. Where appropriate, we will make reasonable adjustments to promote inclusivity.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingFive">
                                <h2 className="mb-0">
                                    <button
                                        className="faq-btn collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                    >
                                        Community Development
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseFive"
                                className="collapse"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="card-body p-5" >
                                    We expect our community to benefit from our presence. Through our Community Engagement Strategy, we will establish stakeholder channels to ensure we:
                                    <ol>
                                        <li>●	Involve our host community in the design and construction of our permanent site</li>
                                        <li>●	Offer training, mentorship and employment to our host community</li>
                                        <li>●	Undertake community projects that add value to the health, economic and social well-being of our host community.</li>
                                        <li>●	Create a scholarship endowment to benefit our host community.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Frequently