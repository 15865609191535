import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Bredcom from "../Bredcom/bredcom";
import {connect} from "react-redux";
import SpinnerLoader from "../../component/common/spinner-loader";

function Search(props) {
    const {param} = useParams();
    const [searchData, setSearchData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (param !== "") {
            let search_array = [];
            //Courses
            const courses = props.courseList.filter(o => o.CourseName.toLowerCase().includes(param.toLowerCase()));
            if (courses.length > 0) {
                courses.map(r => {
                    return search_array.push({title: r.CourseName, link: `/course/${r.Slug}`, type: 'Course'})
                })
            }

            //Faculty
            const faculties = props.facultyList.filter(o => o.FacultyName.toLowerCase().includes(param.toLowerCase()));
            if (faculties.length > 0) {
                faculties.map(r => {
                    return search_array.push({title: r.FacultyName, link: `/faculty/${r.Slug}`, type: 'Faculty'})
                })
            }
            //Staff
            const staff = props.staffList.filter(o => o.FullName.toLowerCase().includes(param.toLowerCase()));
            if (staff.length > 0) {
                staff.map(r => {
                    return search_array.push({title: r.FullName, link: `/staff/${r.Slug}`, type: 'Staff'})
                })
            }
            //News
            const news = props.newsList.filter(o => o.Title.toLowerCase().includes(param.toLowerCase()));
            if (news.length > 0) {
                news.map(r => {
                    return search_array.push({title: r.Title, link: `/news/${r.Slug}`, type: 'News'})
                })
            }
            //Events
            const event = props.eventList.filter(o => o.Title.toLowerCase().includes(param.toLowerCase()));
            if (event.length > 0) {
                event.map(r => {
                    return search_array.push({title: r.Title, link: `/event/${r.Slug}`, type: 'Event'})
                })
            }
            setSearchData(search_array)
            setIsLoading(false)
        }
    },[param])

    return isLoading ? <SpinnerLoader/> : (
        <>
            <Bredcom title="Search" subtitle={`Search => ${param}`}/>
            <section className="shop-area pt-120 pb-120 p-relative " data-animation="fadeInUp animated" data-delay=".2s" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <h6 className="mt-20 mb-50">Showing {searchData.length} results</h6>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        {
                            searchData.length > 0 ?
                                searchData.map((r,i) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 " key={i}>
                                            <div className="courses-item mb-30 hover-zoomin w-100">
                                                <div className="courses-content">
                                                    <div className="cat">
                                                        <i className="fal fa-graduation-cap" /> {r.type}
                                                    </div>
                                                    <h3>
                                                        <Link to={r.link}> {r.title}</Link>
                                                    </h3>
                                                    <Link to={r.link} className="readmore">
                                                        Read More <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) : <p className="alert bg-danger text-white">No Record found for: {param}</p>
                        }


                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        courseList: state.CourseListDetails,
        staffList: state.StaffListDetails,
        newsList: state.NewsDetails,
        eventList: state.EventDetails
    };
};


export default connect(mapStateToProps, null)(Search);
