import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import BredCom from '../Bredcom/bredcom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { ImageUrlFormatter, convertTime, currentDate, formatDate, projectURL, serverLink } from '../../resources/constants';
import * as DOMPurify from 'dompurify';
import axios from 'axios';
import { formatDateAndTime } from '../../resources/constants';
import { titleCase } from 'title-case';

function EventsDetails(props) {
    const { slug } = useParams();
    const type = window.location.href.split("/")[3] === "news" ? "news" : "events"
    const pageData = type === "news" ? props.NewsList : props.EventList;
    const [pageDetails, setPageDetails] = useState(pageData.length > 0 ?
        pageData.filter(x => x.Slug === slug)
        : []);

    useEffect(() => {
        if (type === "events") {
            axios.get(`${serverLink}event/all/active`)
                .then((result) => {
                    const data = result.data;
                    setPageDetails(data.length > 0 ?
                        data.filter(x => x.Slug === slug)
                        : [])
                })
        } else {
            axios.get(`${serverLink}news/all/active`)
                .then((result) => {
                    const data = result.data;
                    setPageDetails(data.length > 0 ?
                        data.filter(x => x.Slug === slug)
                        : [])
                })
        }

    }, [slug])

    const categories = [...new Set(pageData.length > 0 ? pageData.map((item) => { return item.FacultyCode }) : [])]

    const [tDate, settDate] = useState(currentDate);
    const [nextItem, setNextItem] = useState([]);
    const [prevItem, setPrevItem] = useState([])

    useEffect(() => {
        let date;
        if (pageDetails.length > 0) {
            date = type === "events" ? pageDetails[0].EventDate : pageDetails[0].InsertedDate;
            const short_month = formatDateAndTime(date, "short_month");
            const day = date.split("-")[2].split("T")[0];
            const year = date.split("-")[0];
            settDate(date)

            const dd = `${short_month} ${day} ${year}`
            let countDownDate = new Date(`${dd} 15:37:25`).getTime();
            let x = setInterval(function () {
                let now = new Date().getTime();
                let distance = countDownDate - now;

                let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (document.getElementById("day") !== null) document.getElementById("day").innerHTML = days;
                if (document.getElementById("hours") !== null) document.getElementById("hours").innerHTML = hours;
                if (document.getElementById("minutes") !== null) document.getElementById("minutes").innerHTML = minutes;
                if (document.getElementById("seconds") !== null) document.getElementById("seconds").innerHTML = seconds;

                if (distance < 0) {
                    clearInterval(x);
                }
            }, 1000);

            let current_index = pageData.map((x) => { return x.Slug }).indexOf(pageDetails[0].Slug);
            let next_item = pageData.length > current_index + 1 ? pageData[current_index + 1] : pageData[0];
            let prev_item = pageData.length < current_index - 1 ? pageData[current_index - 1] : pageData[0];
            setNextItem([next_item]);
            setPrevItem([prev_item]);
        }
    }, [pageDetails])

    return (
        <>
            {
                pageDetails.length > 0 &&
                <>
                    <BredCom
                        title={type === "events" ? "Events" : "News"}
                        subtitle={type === "events" ? "Event Details" : "News Details"}
                        link={type === "events" ? "/events" : "/news"}
                    />

                    <section className="project-detail">
                        <div className="container">
                            <div className="upper-box">
                                <div className="single-item-carousel owl-carousel owl-theme">
                                    <figure className="image">
                                        <img src={ImageUrlFormatter(pageDetails[0].ImagePath, type === "events" ? "event_uploads" : "news_uploads")} alt="" />
                                    </figure>
                                </div>
                            </div>

                            <div className="lower-content2">
                                <div className="row">
                                    <div className="text-column col-lg-9 col-md-12 col-sm-12">
                                        <div className="s-about-content wow fadeInRight" data-animation="fadeInRight" data-delay=".2s" >
                                            <h3>{pageDetails[0].Title}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pageDetails[0].Description) }} />

                                            {
                                                type === "events" && pageDetails.length > 0 &&
                                                <>
                                                    {
                                                        formatDate(tDate) > formatDate(currentDate) &&
                                                        <div countdown=""
                                                            className="conterdown wow fadeInDown animated"
                                                            data-animation="fadeInDown animated"
                                                            data-delay=".2s"
                                                            data-date="June 17 2023 00:00:00"
                                                        >
                                                            <div className="timer">
                                                                <div className="timer-outer bdr1">
                                                                    <span className="days" data-days="" id='day'> 0 </span>
                                                                    <div className="smalltext">Days</div>
                                                                    <div className="value-bar" />
                                                                </div>
                                                                <div className="timer-outer bdr2">
                                                                    <span className="hours" data-hours="" id='hours'> 0 </span>
                                                                    <div className="smalltext">Hours</div>
                                                                </div>
                                                                <div className="timer-outer bdr3">
                                                                    <span className="minutes" data-minutes="" id='minutes'> 0 </span>
                                                                    <div className="smalltext">Minutes</div>
                                                                </div>
                                                                <div className="timer-outer bdr4">
                                                                    <span className="seconds" data-seconds="" id="seconds"> 0 </span>
                                                                    <div className="smalltext">Seconds</div>
                                                                </div>
                                                                <p id="time-up" />
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {
                                            prevItem.length > 0 &&
                                            <div className="posts_navigation pt-35 pb-35">
                                                <div className="row align-items-md-end">
                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="prev-link">
                                                            <span>Prev Post</span>
                                                            <h4>
                                                                <Link to={`/${type === "events" ? "event" : "news"}/${prevItem[0].Slug}`}>
                                                                    {prevItem[0].Title.length > 35 ? titleCase(prevItem[0].Title.toLowerCase().substr(0, 35)) + "..." :
                                                                        titleCase(prevItem[0].Title.toLowerCase())}
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="next-link text-left text-md-right">
                                                            <span>next Post</span>
                                                            <h4>
                                                                <Link to={`/${type === "events" ? "event" : "news"}/${nextItem[0].Slug}`}>
                                                                    {nextItem[0].Title.length > 35 ? titleCase(nextItem[0].Title.toLowerCase().substr(0, 35)) + "..." :
                                                                        titleCase(nextItem[0].Title.toLowerCase())}
                                                                </Link>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="info-column col-lg-3 col-md-12 col-sm-12">
                                        <div className="inner-column2">
                                            <h3>{type === "events" ? "Event Details" : "News Details"}</h3>
                                            <ul className="project-info clearfix">
                                                {
                                                    type === "events" &&
                                                    <li>
                                                        <span className="icon fal fa-clock" />
                                                        <strong>{convertTime(pageDetails[0].StartTime)} - {convertTime(pageDetails[0].EndTime)}</strong>
                                                    </li>
                                                }
                                                <li>
                                                    <span className="icon fal fa-calendar-alt" />
                                                    <strong>
                                                        {
                                                            type === "events" ?
                                                                <>{formatDateAndTime(pageDetails[0].EventDate, "date")}</> :
                                                                <>{formatDateAndTime(pageDetails[0].InsertedDate, "date")}</>
                                                        }
                                                    </strong>
                                                </li>
                                                {
                                                    type === "events" &&
                                                    <li>
                                                        <span className="icon fal fa-map-marker-check" />
                                                        <strong>{pageDetails[0].Location}</strong>
                                                    </li>
                                                }
                                                {/* <li>
                                                    <span className="icon fal fa-user" />
                                                    <strong>{pageDetails[0].InsertedBy}</strong>
                                                </li> */}
                                                <li>
                                                    <div className="image-column col-xl-12 col-lg-12 col-md-12">
                                                        <div className="footer-social mt-10">
                                                            <a
                                                                style={{ backgroundColor: "#a18b5c" }}
                                                                target='_blank'
                                                                href={`http://www.facebook.com/sharer/sharer.php?u=${projectURL}news/${pageDetails[0].Slug}`} >
                                                                <i className="fab fa-facebook-f" />
                                                            </a>
                                                            <a
                                                                target='_blank'
                                                                style={{ backgroundColor: "#a18b5c" }}
                                                                href={`https://www.instagram.com/?url=${projectURL}news/${pageDetails[0].Slug}`}>
                                                                <i className="fab fa-instagram" />
                                                            </a>
                                                            <a
                                                                target='_blank'
                                                                style={{ backgroundColor: "#a18b5c" }}
                                                                href={`https://twitter.com/share?text=${pageDetails[0].Title}&url=${projectURL}news/${pageDetails[0].Slug}`}>
                                                                <i className="fab fa-twitter" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* <div className="slider-btn">
                                                        <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                            Book A Seat <i className="fal fa-long-arrow-right" />
                                                        </Link>
                                                    </div> */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        NewsList: state.NewsDetails,
        EventList: state.EventDetails
    }
}

export default connect(mapStateToProps, null)(EventsDetails);
