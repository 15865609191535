import React, {useEffect, useState} from 'react'
import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/magnific-popup.css'
import './assets/fontawesome/css/all.min.css'
import './assets/css/dripicons.css'
import './assets/css/slick.css'
import './assets/css/meanmenu.css'
import './assets/css/default.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import Header from './component/headerone/Main'
import Footer from './component/footer/Main'
import {connect} from "react-redux";
import {
    setCourseDataDetails, setCourseListDetails, setDepartmentListDetails,
    setEventDetails, setFacultyDataDetails, setFacultyListDetails,
    setHomepageContentDetails,
    setNewsDetails, setStaffListDetails
} from "./actions/setactiondetails";
import axios from "axios";
import { serverLink } from './resources/constants';
import SpinnerLoader from "./component/common/spinner-loader";

function App(props) {
    const [isLoading, setIsLoading] = useState(props.facultyList.length > 0 && props.facultyDetail.length > 0 && props.departmentList.length > 0 && props.courseList.length > 0 && props.courseDetails.length > 0);
    const getData = async () => {
        await axios
            .get(`${serverLink}academics/faculty/academic/list`)
            .then((response) => {
                props.setOnFacultyListDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
        await axios
            .get(`${serverLink}academics/department/academic/list`)
            .then((response) => {
                props.setOnDepartmentListDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/faculty/details`)
            .then((response) => {
                props.setOnFacultyDataDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((response) => {
                props.setOnCourseListDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/course/details`)
            .then((response) => {
                props.setOnCourseDataDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}news/all/active`)
            .then((result) => {
                const data = result.data;
                props.setOnNewsDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });

        await axios
            .get(`${serverLink}event/all/active`)
            .then((result) => {
                const data = result.data;
                props.setOnEventDetails(data);
            })
            .catch((error) => {
                console.log("error", error);
            });
        await axios
            .get(`${serverLink}general/homepage/content`)
            .then((result) => {
                const data = result.data;
                props.setOnHomepageContentDetails(data[0]);
            })
            .catch((error) => {
                console.log("error", error);
            });
        await axios
            .get(`${serverLink}academic/staff/website/all`)
            .then((response) => {
                props.setOnStaffListDetails(response.data);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, []);
  return !isLoading ? (
    <>
      <Router>
        <Header />
        <Routing />
        <Footer />
      </Router>
    </>
  ) : <SpinnerLoader />;
}


const mapStateToProps = (state) => {
    return {
        facultyList: state.FacultyListDetails,
        facultyDetail: state.FacultyDataDetails,
        courseList: state.CourseListDetails,
        courseDetails: state.CourseDataDetails,
        departmentList: state.DepartmentListDetails,
        eventList: state.EventDetails,
        newsList: state.NewsDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnFacultyListDetails: (p) => {
            dispatch(setFacultyListDetails(p));
        },
        setOnFacultyDataDetails: (p) => {
            dispatch(setFacultyDataDetails(p));
        },
        setOnDepartmentListDetails: (p) => {
            dispatch(setDepartmentListDetails(p));
        },
        setOnCourseListDetails: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseDataDetails: (p) => {
            dispatch(setCourseDataDetails(p));
        },
        setOnEventDetails: (p) => {
            dispatch(setEventDetails(p));
        },
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
        setOnHomepageContentDetails: (p) => {
            dispatch(setHomepageContentDetails(p));
        },
        setOnStaffListDetails: (p) => {
            dispatch(setStaffListDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
