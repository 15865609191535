import React  from 'react'
import { Link } from 'react-router-dom';
import {
    projectFacebook,
    projectInstagram,
    projectLinkedIn,
    projectTwitter,
    projectYoutube
} from "../../resources/constants";
function ResearchGroupContent() {
    return (
        <>
            <section className="inner-blog pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> Research Groups and Centers
                                    </h5>
                                </div>
                                <div style={{textAlign: "justify"}}>
                                    <p>
                                        Cosmopolitan University is committed to fostering a culture of research excellence and innovation. To facilitate interdisciplinary collaboration and advance knowledge in specific research areas, we have established various Research Groups and Centers within our academic programs. These groups and centers bring together faculty members, researchers, and students who share a common research interest and aim to address complex challenges. Here are some of our prominent Research Groups and Centers based on the programs we offer:
                                    </p>

                                        <h3>Faculty of Management and Social Sciences: </h3>

                                    <p>
                                        <strong>Centre for Financial Innovation and Risk Management: </strong>
                                        This center focuses on research related to financial markets, risk assessment, financial modeling, and innovative financial instruments. It explores cutting-edge strategies to manage financial risks and promote financial innovation.
                                    </p>
                                    <p>
                                        <strong>Centre for Entrepreneurship and Innovation: </strong>
                                        This center fosters research and innovation in entrepreneurship, venture creation, social entrepreneurship, and innovation management. It aims to develop an entrepreneurial ecosystem and support the growth of start-ups and innovative enterprises.
                                    </p>
                                    <p>
                                        <strong>Centre for Human Resource Development: </strong>
                                        This center conducts research on talent management, leadership development, employee engagement, and organizational behavior. It explores strategies for attracting, developing, and retaining a skilled workforce in dynamic business environments.
                                    </p>
                                    <p>
                                        <strong>Centre for Development Economics and Policy: </strong>
                                        This center focuses on research in development economics, poverty alleviation, inequality, sustainable development, and public policy analysis. It aims to contribute to evidence-based policymaking and promote inclusive economic growth.
                                    </p>

                                        <h3>Faculty of Medical Sciences: </h3>

                                    <p>
                                        <strong>Centre for Healthcare Research and Innovation: </strong>
                                        This center conducts research on healthcare delivery systems, patient safety, healthcare technology, health policy, and health informatics. It aims to improve the quality and effectiveness of healthcare services through evidence-based practices.
                                    </p>
                                    <p>
                                        <strong>Centre for Biomedical Sciences: </strong>
                                        This center focuses on research in biomedical sciences, molecular biology, genetics, and biomedical engineering. It aims to advance medical diagnostics, develop new therapies, and contribute to the understanding of human diseases.
                                    </p>
                                    <p>
                                        <strong>Centre for Public Health Research: </strong>
                                        This center conducts research on epidemiology, health promotion, disease prevention, environmental health, and public health interventions. It aims to address public health challenges and promote the well-being of communities.
                                    </p>

                                        <h3>Faculty of Science and Engineering: </h3>

                                    <p>
                                        <strong>Centre for Artificial Intelligence and Data Science: </strong>
                                         This center explores research in artificial intelligence, machine learning, data analytics, and computer vision. It aims to develop innovative algorithms and applications that leverage the power of AI and data science.
                                    </p>
                                    <p>
                                        <strong>Centre for Cyber security and Privacy: </strong>
                                        This center focuses on research in cyber security, privacy-enhancing technologies, network security, digital forensics, and secure software development. It aims to address emerging cyber security threats and protect digital assets.
                                    </p>
                                    <p>
                                        <strong>Centre for Advanced Computing and Technology: </strong>
                                        This center conducts research on high-performance computing, distributed systems, cloud computing, and advanced computing technologies. It aims to drive innovation in computing systems and applications.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" style={{textAlign: "justify"}}>
                                <p>
                                    These Research Groups and Centers provide a collaborative and supportive environment for faculty members, researchers, and students to engage in impactful research projects, share knowledge, and contribute to the advancement of their respective fields. Through these interdisciplinary collaborations, we aim to address complex challenges, make significant discoveries, and positively impact society.
                                </p>
                                <p>
                                    Join us at Cosmopolitan University and be part of our dynamic research community, where innovation thrives, and knowledge knows no boundaries.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="custom_html-5" className="widget_text widget widget_custom_html" >
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href={projectTwitter} title="Twitter" target="_blank">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href={projectYoutube} title="Youtube" target="_blank">
                                                <i className="fab fa-youtube" />
                                            </a>
                                            <a href={projectFacebook} title="Facebook" target="_blank">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href={projectInstagram} title="Instagram" target="_blank">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href={projectLinkedIn} title="LinkedIn" target="_blank">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories mb-5 mt-5">
                                    <h2 className="widget-title">Quick Links</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <Link to="/admission/fees">Fees</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-23">
                                            <Link to="/news">News</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-18">
                                            <Link to="/events">Event</Link>
                                        </li>
                                        <hr style={{border: '1px solid #cccccc', margin: '4px'}}/>
                                        <li className="cat-item cat-item-22">
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                    </ul>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ResearchGroupContent