import React from 'react'
import { Link } from 'react-router-dom'

export default function WelcomeMessage() {
    return (
        <div>
            <section className="about-area about-p pt-5 pb-120 p-relative fix" style={{ backgroundImage: "url(assets/img/bg/admission_bg.png)", backgroundRepeat: "no-repeat", backgroundPosition: "top", backgroundColor: '#eeeeee' }} >
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img  src={require("../../assets/images/VC.jpg")} alt="img" style={{ borderRadius: "10px" }} />
                                <div className="text-center mt-4">
                                    <h4>Prof. Carl Adams</h4>
                                    <p> Vice-Chancellor </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-title second-title pl-3 pb-25">
                                <h3 className="">Message from the Vice-Chancellor</h3>
                            </div>
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >

                                <p className="" style={{textAlign: 'justify'}}>
                                    Cosmopolitan University of Abuja is a dynamic university that provides high quality research informed education to our students. We continue to invest in our staff, students, and facilities as part of our goal to become a leading university in Nigeria recognised internationally. Our mission is to educate to the highest standards and conduct impactful research contributing towards a better future for all. Inclusivity and diversity are at the heart of our community.
                                </p>
                                <p className="" style={{textAlign: 'justify'}}>
                                    Our teaching aims to provide high-quality courses providing robust learning experiences preparing students for their careers. We draw upon an international faculty and resources bringing global best practices and insights. Our courses use the best of learning materials from around the world ensuring exacting standards making our graduates very marketable in their professional careers and thought leaders of the future.
                                </p>
                                <div className="slider-btn mt-20">
                                    <Link to="/welcome-message" className="btn ss-btn smoth-scroll">
                                        Read More <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
