import React, {useState} from 'react'
import backgroundImage from '../../assets/images/space1.webp'
import {Link} from "react-router-dom";

function MakerSpaceContent() {
    const [video, setVideo] = useState();

    return (
        <>
            <section className="event event03 pt-50 pb-120 p-relative fix text-start">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2> <i className="fal fa-space-shuttle" /> Welcome to the Maker Space at Cosmopolitan University!</h2>
                            <p>The Maker Space is a dynamic and innovative hub where creativity, collaboration, and hands-on learning come together. It is a dedicated space on our campus that provides students with the tools, resources, and support they need to bring their ideas to life and engage in experiential learning. Whether you're a student, researcher, or faculty member, the Maker Space is open to everyone who is passionate about exploring, designing, and creating.</p>
                        </div>
                        <div className="col-lg-5 col-md-5  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="s-video-wrap2" style={{ backgroundImage: `url(${backgroundImage})`}} >
                                <div className="s-video-content text-center">
                                    {/* <h6>
                                        <Link to="#" className="popup-video mb-50" onClick={() => setVideo(true)}>
                                            <img src="/assets/img/bg/play-button.png" alt="circle_right" />
                                        </Link>
                                    </h6> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="faq-wrap pl-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                <h4>Here's what you can expect from our Maker Space: </h4>
                                <div className="accordion" id="accordionCU">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="faq-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" >
                                                    State of the Art Equipment
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse show" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                Our Maker Space is equipped with state-of-the-art tools, machinery, and technology to support a wide range of projects and disciplines. From 3D printers, laser cutters, and CNC machines to electronics, woodworking tools, and prototyping equipment, you'll find everything you need to turn your ideas into tangible prototypes or products.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" >
                                                    Collaborative Environment
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                The Maker Space is designed to foster collaboration and interdisciplinary interaction. It brings together individuals from various fields and backgrounds, allowing for the exchange of ideas and knowledge. You'll have the opportunity to collaborate with like-minded peers, share expertise, and learn from each other's experiences.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" >
                                                    Expert Guidance and Support
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                Our knowledgeable staff and experienced mentors are available to provide guidance and support throughout your maker journey. They can assist you in learning how to operate equipment, troubleshoot technical issues, and provide insights on design and fabrication techniques. Whether you're a beginner or an advanced maker, our team is here to help you unleash your creativity and maximize your potential.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" >
                                                    Training and Workshops
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                We offer training sessions and workshops on a variety of maker-related topics. These sessions cover basic safety protocols, equipment operation, design software, and advanced fabrication techniques. The goal is to empower you with the skills and knowledge necessary to utilize the Maker Space effectively and safely. Our workshops also provide opportunities to learn new skills, explore emerging technologies, and engage in hands-on activities.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" >
                                                    Open Access and Inclusivity
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                The Maker Space is open to all members of the university community. We embrace inclusivity and diversity, welcoming individuals from various backgrounds, disciplines, and skill levels. Whether you're an undergraduate student, graduate student, faculty member, or researcher, you are encouraged to explore the possibilities of making and bring your ideas to life in our inclusive and supportive environment.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingSix">
                                            <h2 className="mb-0">
                                                <button className="faq-btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" >
                                                    Project Showcase and Competitions
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSix" className="collapse" data-bs-parent="#accordionCU" >
                                            <div className="card-body">
                                                The Maker Space is not just a place to create; it's also a platform to showcase your projects and celebrate innovation. We organize regular project showcases where you can exhibit your work to the university community, industry professionals, and potential collaborators. Additionally, we host maker competitions and challenges to encourage friendly competition and recognize exceptional creativity and craftsmanship.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 text-center">
                            <p>The Maker Space at Cosmopolitan University is a hub of innovation, creativity, and collaboration. It's where ideas are transformed into reality, and where hands-on learning takes center stage. Join us in this exciting journey of exploration, discovery, and making. Unleash your creativity, push the boundaries of what's possible, and let your imagination soar in our vibrant Maker Space! .</p>
                        </div>
                    </div>
                </div>
            </section>

            {video &&
                <>
                    <div className="mfp-bg mfp-ready"></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style={{overflow: "hidden"}}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
                            <div className="mfp-content">
                                <div className="mfp-iframe-scaler">
                                    <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setVideo(false)} >×</button>
                                    <iframe className="mfp-iframe" src="//www.youtube.com/embed/ZSiXZxVpVhs?autoplay=1" frameborder="0" allowfullscreen=""></iframe>
                                </div>
                            </div>
                            <div className="mfp-preloader">Loading...</div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}

export default MakerSpaceContent