import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import OurStudent from "./our-student";
function TalkToOurStudent() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Talk to our Student"/>
            <OurStudent/>
        </>
    )
}

export default TalkToOurStudent