import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import University from '../../pages/about-us/university'
function AboutUs() {
    return (
        <>
            <Bredcom title="Home"  subtitle="About Us"/>
            <University/>
        </>
    )
}

export default AboutUs