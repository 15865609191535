import React from 'react'
import Bredcom from '../../pages/Bredcom/bredcom'
import Campus from "./campus";
import backgroundImage from '../../assets/images/banner2_dark - Copy.png'

function LifeOnCampus() {
    return (
        <>
            <Bredcom title="Home"  subtitle="Life on Campus" bgImage={backgroundImage}/>
            <Campus/>
        </>
    )
}

export default LifeOnCampus